<template>
  <ul v-if="getProfileInfo">
    <div class="mb-5 pb-5">


    </div>

    <component
        :is="resolveNavItemComponent(item)"
        v-for="item in this.items"
        :key="item.header || item.title"
        :item="item"
        v-permission="item.permissions"
    />

  </ul>
</template>

<script>
import {resolveVerticalNavMenuItemComponent as resolveNavItemComponent} from '@core/layouts/utils';
import {provide, ref} from '@vue/composition-api';
import {mapGetters} from 'vuex';
import VerticalNavMenuHeader from '../vertical-nav-menu-header';
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue';
import VerticalNavMenuGroup from '../vertical-nav-menu-group/VerticalNavMenuGroup.vue';

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      getProfileInfo: 'profile/getBasic',
    }),
  },
  methods: {
    shortenText(text, length) {
      return text.length > length ? text.split('').splice(0, length).join('').concat('', ' ...') : text;
    },
    filteredItems(val) {
      const [reports] = val.restrictions.filter(
          item => item.name === 'reports',
      );
      const [dashboard] = val.restrictions.filter(
          item => item.name === 'dashboard',
      );
      if (
          val.complete_status === 'pending'
          || val.complete_status === 'confirming'
          || val.complete_status === 'suspended'
          || val.complete_status === 'rejected'
      ) {
        return [this.items[0]];
      } else if (dashboard.code === '02_07_01' || reports.code === '02_06_01') {
        const items = this.items.filter(item => {
          if (item.title === 'financial_operations' || item.title === 'dashboard') {
            return;
          }
          return item;
        });
        return items;
      }
      return this.items;
    },
  },

  setup() {
    provide('openGroups', ref([]));

    return {
      resolveNavItemComponent,
    };
  },
};
</script>
