<template>
  <div>
    <layout-vertical>
      <router-view />
    </layout-vertical>
    <loading />
  </div>
</template>

<script>
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue';
import { $themeConfig } from '@themeConfig';
import Loading from '@/components/wameed/Wameedloading.vue';

export default {
  components: {
    LayoutVertical,
    Loading,
  },
  data() {
    return {
      showCustomizer: $themeConfig.layout.customizer,
    };
  },
  async created() {
    await this.$store.dispatch('profile/basic');
    this.$store.dispatch('admin/notifications/limit')
  }
};
</script>
